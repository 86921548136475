import React from "react";
import Layout from "@/Layout";
import Router from "next/router";
import { get, post } from "$ACTIONS/TlcRequest";
import { Cookie } from "$ACTIONS/util";
import { ApiPort, APISETS, StrapiApi } from "$ACTIONS/TLCAPI";
import { Modal, Row, Col, Tabs, Icon, message, Spin } from "antd";
import { getUrlWithAffCode } from "$ACTIONS/helper";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Banner from "@/Banner";
import QRCode from "qrcode-react";
import LazyLoad from "react-lazyload";
import Bannerimg from "@/Banner/img";
import Image from "next/image";
import { isWebPSupported } from "$ACTIONS/helper";
import ImageWithFallback from "@/ImageWithFallback/imgLocal";
// import BankStatus from "@/BankStatus";
import { getStaticPropsFromStrapiSEOSetting } from "$DATA/seo";
import { storePagePiwikData, clearPagePiwikData } from "$ACTIONS/piwikData";

export async function getStaticProps() {
    return await getStaticPropsFromStrapiSEOSetting("/"); //參數帶本頁的路徑
}

const { TabPane } = Tabs;

export default class Main extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            bannerList: [],
            isLogin: false,
            downloadLinks: "",
            promotionbannerList: "",
            sponsorbannerList: "",
            showCtcWin: true,
            eightShopLoading: false,
            promotionCurrKey: "0",
            mainShopHref: "",
            newsList: {},
        };
        this.baseUrl = StrapiApi + "/th-th/api/v1/web/webbanners/position/";

        this.getBannerList = this.getBannerList.bind(this); // 獲取 main、feature、sponsor Banner 列表
        this.defaultBannerList = this.defaultBannerList.bind(this); // 默认获取登录前的Banner列表
        this.mainPromotionSplitPage = this.mainPromotionSplitPage.bind(this);
    }
    isJSON(str) {
        if (typeof str == "string") {
            try {
                var obj = JSON.parse(str);
                if (typeof obj == "object" && obj) {
                    return obj;
                } else {
                    return false;
                }
            } catch (e) {
                console.log("error：" + str + "!!!" + e);
                return false;
            }
        }
        console.log("It is not a string!");
    }

    componentDidMount() {
        this.Downloadidopen();
        this.getBannerList(); // 獲取 main、feature、sponsor Banner 列表
        global.Pushgtagpiwikurl && global.Pushgtagpiwikurl("home", "Home");
        storePagePiwikData("home", "Home");
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.isLogin !== this.state.isLogin) {
            this.getBannerList(); // 獲取 main、feature、sponsor Banner 列表
        }
    }

    componentWillUnmount() {
        clearPagePiwikData();
    }

    getBannerList() {
        const LocalFeature = localStorage.getItem("LocalFeature");
        const LocalSponsor = localStorage.getItem("LocalSponsor");
        const LocalFeatureParse = this.isJSON(LocalFeature),
            LocalSponsorParse = this.isJSON(LocalSponsor);
        LocalFeatureParse &&
            this.setState({ promotionbannerList: LocalFeatureParse });
        LocalSponsorParse &&
            this.setState({ sponsorbannerList: LocalSponsorParse });

        const isSupportWebp = isWebPSupported() ? "&displaying_webp" : "";
        let loginStatus =
            sessionStorage.getItem("isLogin") &&
            localStorage.getItem("access_token"); //已登录状态
        const loginParam = loginStatus ? "after" : "before";

        const mainUrl = `${this.baseUrl}home_main?login=${loginParam}${isSupportWebp}`;
        /* mainBanner列表 */
        get(mainUrl).then(this.defaultBannerList);

        const featureUrl = `${this.baseUrl}home_feature?login=${loginParam}${isSupportWebp}`;
        /* 优惠列表 */
        get(featureUrl).then((res) => {
            let result = this.formatPromotionListData(res);
            const localStr = JSON.stringify(result);
            localStorage.setItem("LocalFeature", localStr);
            localStr !== LocalFeature &&
                result &&
                this.setState({ promotionbannerList: result });
        });

        const sponsorUrl = `${this.baseUrl}home_sponsor?login=${loginParam}${isSupportWebp}`;
        /* 赞助列表 */
        get(sponsorUrl).then((res) => {
            const localStr = JSON.stringify(res);
            localStorage.setItem("LocalSponsor", localStr);
            localStr !== LocalSponsor &&
                res &&
                this.setState({ sponsorbannerList: res });
        });
    }

    /**
     * 根据单页长度，按照几个长度进行分页，格式化响应的精彩活动
     * @param {Array} res 需要处理分页的数据
     * @param {Int} singlePageSize 单页需要展示的长度
     * @param {Int} splitPageSize 实际分页的参考长度
     * @returns 转换后的结果
     */
    formatPromotionListData(res, singlePageSize = 3, splitPageSize = 2) {
        let result = [];
        let len = res && Math.ceil(res.length / splitPageSize);

        for (let i = 0; i < len; i++) {
            let tempIndex = i * splitPageSize; // 当前页起始下标
            let tempPageData = []; // 当前下标数据暂存值
            for (let j = 0; j < singlePageSize; j++) {
                const tempData = res[tempIndex + j]; // 当前页单个数据暂存值
                !!tempData && tempPageData.push(tempData);
            }
            result.push(tempPageData);
        }

        return result;
    }

    defaultBannerList(res) {
        res && this.setState({ bannerList: res });
    }

    async Downloadidopen() {
        const url = await getUrlWithAffCode();
        global.downloadLinks = url;
        this.setState({ downloadLinks: url });
    }

    // 首页精彩活动翻页
    mainPromotionSplitPage(direction) {
        const { promotionCurrKey, promotionbannerList } = this.state;
        const currKey = parseInt(promotionCurrKey);
        direction === "up" &&
            currKey > 0 &&
            this.setState({ promotionCurrKey: (currKey - 1).toString() });
        direction === "down" &&
            currKey < promotionbannerList.length - 1 &&
            this.setState({ promotionCurrKey: (currKey + 1).toString() });
    }
    render() {
        const { promotionbannerList, sponsorbannerList } = this.state;
        return (
            <Layout
                seoData={this.props.seoData}
                setLoginStatus={(v) => {
                    this.setState({ isLogin: v });
                }}
            >
                {/* Online/Offline bank status，暫時註解，待 phase2 */}
                {/* <BankStatus /> */}
                {/* Banner 图片 */}
                <Banner
                    // bannerClick={this.bannerClick}
                    bannerList={this.state.bannerList}
                />
                {/* 主体内容区域 */}
                <div className="common-distance-wrap">
                    <div className="common-distance">
                        {Array.isArray(sponsorbannerList) &&
                            sponsorbannerList.length != 0 && (
                                <React.Fragment>
                                    <h2 className="home-section-title">
                                        โปรโมชั่น
                                        {/* 我的优惠 */}
                                    </h2>
                                    {sponsorbannerList != "" &&
                                    Array.isArray(sponsorbannerList) ? (
                                        <Tabs
                                            type="card"
                                            className="home-promotion-wrap _2 promotionBanner"
                                        >
                                            {sponsorbannerList.map(
                                                (item, index) => {
                                                    return (
                                                        <TabPane
                                                            tab={
                                                                <div
                                                                    className="home-sponsor offset-y"
                                                                    // onClick={() => {}}
                                                                >
                                                                    <LazyLoad
                                                                        height={
                                                                            120
                                                                        }
                                                                    >
                                                                        <Bannerimg
                                                                            item={
                                                                                item
                                                                            }
                                                                            width={
                                                                                540
                                                                            }
                                                                            height={
                                                                                119
                                                                            }
                                                                        />
                                                                    </LazyLoad>
                                                                </div>
                                                            }
                                                            key={index}
                                                        />
                                                    );
                                                }
                                            )}
                                        </Tabs>
                                    ) : (
                                        <div className="ant-skeleton ant-skeleton-active">
                                            <div className="ant-skeleton-content">
                                                <ul className="ant-skeleton-paragraph">
                                                    <li
                                                        style={{ height: 133 }}
                                                    />
                                                </ul>
                                            </div>
                                        </div>
                                    )}
                                </React.Fragment>
                            )}

                        {Array.isArray(promotionbannerList) &&
                            promotionbannerList.length != 0 && (
                                <React.Fragment>
                                    <h2 className="home-section-title">
                                        <span>กิจกรรมที่น่าสนใจ</span>
                                        {/* 精彩活动 */}
                                        <div
                                            className="home-split-page-btn-wrap"
                                            style={{
                                                display:
                                                    promotionbannerList != "" &&
                                                    Array.isArray(
                                                        promotionbannerList
                                                    ) &&
                                                    promotionbannerList.length >
                                                        1
                                                        ? "block"
                                                        : "none",
                                            }}
                                        >
                                            {this.state.promotionCurrKey !=
                                            0 ? (
                                                <span
                                                    onClick={() => {
                                                        this.mainPromotionSplitPage(
                                                            "up"
                                                        );
                                                    }}
                                                    unselectable="unselectable"
                                                    className="ant-tabs-tab-prev ant-tabs-tab-arrow-show"
                                                >
                                                    <span className="ant-tabs-tab-prev-icon">
                                                        <i
                                                            aria-label="图标: left"
                                                            className="anticon anticon-left ant-tabs-tab-prev-icon-target"
                                                        >
                                                            <svg
                                                                viewBox="64 64 896 896"
                                                                focusable="false"
                                                                className=""
                                                                data-icon="left"
                                                                width="1em"
                                                                height="1em"
                                                                fill="currentColor"
                                                                aria-hidden="true"
                                                            >
                                                                <path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 0 0 0 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z" />
                                                            </svg>
                                                        </i>
                                                    </span>
                                                </span>
                                            ) : null}
                                            {promotionbannerList &&
                                            promotionbannerList.length &&
                                            promotionbannerList.length - 1 !=
                                                this.state.promotionCurrKey ? (
                                                <span
                                                    onClick={() => {
                                                        this.mainPromotionSplitPage(
                                                            "down"
                                                        );
                                                    }}
                                                    unselectable="unselectable"
                                                    className="ant-tabs-tab-next ant-tabs-tab-arrow-show"
                                                >
                                                    <span className="ant-tabs-tab-next-icon">
                                                        <i
                                                            aria-label="图标"
                                                            className="anticon anticon-right ant-tabs-tab-next-icon-target"
                                                        >
                                                            <svg
                                                                viewBox="64 64 896 896"
                                                                focusable="false"
                                                                className=""
                                                                data-icon="right"
                                                                width="1em"
                                                                height="1em"
                                                                fill="currentColor"
                                                                aria-hidden="true"
                                                            >
                                                                <path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 0 0 302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 0 0 0-50.4z" />
                                                            </svg>
                                                        </i>
                                                    </span>
                                                </span>
                                            ) : null}
                                        </div>
                                    </h2>
                                    {promotionbannerList != "" &&
                                    Array.isArray(promotionbannerList) ? (
                                        <div>
                                            <Tabs
                                                className="home-split-page-promotion-wrap"
                                                activeKey={
                                                    this.state.promotionCurrKey
                                                }
                                            >
                                                {promotionbannerList.map(
                                                    (item, index) => {
                                                        return (
                                                            <TabPane
                                                                tab={null}
                                                                key={index}
                                                            >
                                                                {item.map(
                                                                    (
                                                                        panelItem,
                                                                        panelIndex
                                                                    ) => {
                                                                        return (
                                                                            <div
                                                                                key={
                                                                                    "panel" +
                                                                                    panelIndex
                                                                                }
                                                                                className="home-promotion offset-y"
                                                                                onClick={() => {
                                                                                    Pushgtagdata(
                                                                                        "Home",
                                                                                        "Click Feature Banner",
                                                                                        "Home_C_FeatureBanner",
                                                                                        false,
                                                                                        [
                                                                                            {
                                                                                                customVariableKey:
                                                                                                    "Home_C_FeatureBanner_ActivityName",
                                                                                                customVariableValue:
                                                                                                    panelItem.title,
                                                                                            },
                                                                                        ]
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <LazyLoad
                                                                                    height={
                                                                                        100
                                                                                    }
                                                                                    offset={
                                                                                        100
                                                                                    }
                                                                                    once
                                                                                >
                                                                                    <Bannerimg
                                                                                        item={
                                                                                            panelItem
                                                                                        }
                                                                                        width={
                                                                                            480
                                                                                        }
                                                                                        height={
                                                                                            100
                                                                                        }
                                                                                    />
                                                                                </LazyLoad>
                                                                            </div>
                                                                        );
                                                                    }
                                                                )}
                                                            </TabPane>
                                                        );
                                                    }
                                                )}
                                            </Tabs>
                                        </div>
                                    ) : (
                                        <div className="ant-skeleton ant-skeleton-active">
                                            <div className="ant-skeleton-content">
                                                <ul className="ant-skeleton-paragraph">
                                                    <li
                                                        style={{ height: 116 }}
                                                    />
                                                </ul>
                                            </div>
                                        </div>
                                    )}
                                </React.Fragment>
                            )}
                    </div>
                </div>
                <div className="common-distance-wrap app-qrcode-wrap">
                    <div className="common-distance">
                        <Row gutter={20}>
                            <Col span={9}>
                                <LazyLoad height={401} offset={100} once>
                                    <div className="app-picture">
                                        <div className="AppDownloadSection">
                                            <ImageWithFallback
                                                src={`/th/img/home/part/AppDownloadSection.${
                                                    isWebPSupported()
                                                        ? "webp"
                                                        : "png"
                                                }`}
                                                width={401}
                                                height={382}
                                                alt="app-picture"
                                                fallbackSrc="/th/img/logo.svg"
                                            />
                                        </div>
                                    </div>
                                </LazyLoad>
                            </Col>
                            <Col span={14}>
                                <Row className="qrcode">
                                    <Col span={13}>
                                        <h1 className="title">
                                            เนทีฟแอป FUN88
                                        </h1>
                                        {/* APP 下载 */}
                                        <div className="qrcode-article-wrap">
                                            <p className="qrcode-article">
                                                มอบความปลอดภัย
                                                และประสิทธิภาพสูงสุดในการเข้าใช้งาน
                                                พร้อมฟังก์ชั่นการโอนเงินรูปแบบใหม่
                                                ที่เร็วขึ้น และปลอดภัยมากขึ้น
                                                เพียงคลิก! ความสนุกตื่นเต้น
                                                และการได้ลุ้นแบบเรียลไทม์
                                                คุณจะไม่พลาด ทุกความเคลื่อนไหว
                                                กับเว็บไซต์ออนไลน์ที่ดีที่สุดในเอเชีย
                                                รองรับทั้งระบบปฏิบัติการแอนดรอยด์
                                                และ iOS เพราะรางวัลก้อนใหญ่
                                                อยู่ที่ปลายนิ้วคุณเอง!!
                                                {/* 乐天堂倾情打造，业内最顶尖的手机投注APP。体育、电竞、真人、老虎机、棋牌、彩票、数万种游戏玩法应有尽有。全站游戏适应所有移动设备，精彩游戏畅享指尖！ */}
                                            </p>
                                            <i className="tlc-sprite app-down-icons" />
                                            <h4>
                                                คัดลอกลิงก์เพื่อดาวน์โหลดแอป :
                                            </h4>
                                            {/* 手机浏览器输入网址： */}
                                            <CopyToClipboard
                                                text={this.state.downloadLinks}
                                                onCopy={() => {
                                                    message.success(
                                                        "คัดลอกสำเร็จ" //复制成功！
                                                    );
                                                }}
                                            >
                                                <div className="homeinstallurl">
                                                    {this.state.downloadLinks}
                                                    <img
                                                        src="/th/img/icons/copy.svg"
                                                        className="copy-icon"
                                                        alt="copy"
                                                    />
                                                </div>
                                            </CopyToClipboard>
                                        </div>
                                    </Col>
                                    <Col span={6} offset={1}>
                                        <div className="App-Qrcode-List">
                                            <LazyLoad
                                                height={175}
                                                offset={100}
                                                once
                                            >
                                                <div className="qrcode-picture">
                                                    {this.state
                                                        .downloadLinks ? (
                                                        <QRCode
                                                            //logo={'/th/img/download/qrlogo.jpg'}
                                                            size={148}
                                                            value={
                                                                this.state
                                                                    .downloadLinks
                                                            }
                                                            // logoWidth={50}
                                                            // logoHeight={50}
                                                        />
                                                    ) : null}
                                                    <h5 className="App-Name">
                                                        เนทีฟแอป FUN88
                                                    </h5>
                                                </div>
                                            </LazyLoad>
                                            <LazyLoad
                                                height={175}
                                                offset={100}
                                                once
                                            >
                                                <div className="qrcode-picture">
                                                    {this.state
                                                        .downloadLinks ? (
                                                        <QRCode
                                                            //logo={'/th/img/download/qrlogo.jpg'}
                                                            size={148}
                                                            value={
                                                                this.state
                                                                    .downloadLinks
                                                            }
                                                            // logoWidth={50}
                                                            // logoHeight={50}
                                                        />
                                                    ) : null}
                                                    <h5 className="App-Name">
                                                        แชทสด
                                                    </h5>
                                                </div>
                                            </LazyLoad>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
                <LazyLoad height={454} offset={100}>
                    <div className="common-distance-wrap cooperate-wrap">
                        <div className="common-distance clear-padding">
                            <h1 className="cooperate-title">หุ้นส่วน</h1>
                            {/* 合作伙伴 */}
                            <Row type="flex" justify="center" align="bottom">
                                <Col span={10} offset={1}>
                                    <LazyLoad height={808} offset={100} once>
                                        <ImageWithFallback
                                            src={`/th/img/home/part/newfooter.${
                                                isWebPSupported()
                                                    ? "webp"
                                                    : "png"
                                            }`}
                                            width={480}
                                            height={299}
                                            alt="app-picture"
                                            fallbackSrc="/th/img/logo.svg"
                                        />
                                    </LazyLoad>
                                </Col>
                                <Col span={13}>
                                    <LazyLoad height={808} offset={100} once>
                                        <ImageWithFallback
                                            src={`/th/img/home/part/cooperate-people_big.${
                                                isWebPSupported()
                                                    ? "webp"
                                                    : "png"
                                            }`}
                                            width={628}
                                            height={318}
                                            alt="app-picture"
                                            fallbackSrc="/th/img/logo.svg"
                                        />
                                    </LazyLoad>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </LazyLoad>
            </Layout>
        );
    }
}
